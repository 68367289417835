// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ErrorDisplay.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ErrorDisplay.tsx");
  import.meta.hot.lastModified = "1709804301412.501";
}
// REMIX HMR END

import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import { LinkButton } from "./primitives/Buttons";
import { Header1, Header3 } from "./primitives/Headers";
import { friendlyErrorDisplay } from "~/utils/httpErrors";
export function RouteErrorDisplay(options) {
  _s();
  const error = useRouteError();
  return <>
      {isRouteErrorResponse(error) ? <ErrorDisplay title={friendlyErrorDisplay(error.status, error.statusText).title} message={error.data.message ?? friendlyErrorDisplay(error.status, error.statusText).message} {...options} /> : error instanceof Error ? <ErrorDisplay title={error.name} message={error.message} {...options} /> : <ErrorDisplay title="Oops" message={JSON.stringify(error)} {...options} />}
    </>;
}
_s(RouteErrorDisplay, "oAgjgbJzsRXlB89+MoVumxMQqKM=", false, function () {
  return [useRouteError];
});
_c = RouteErrorDisplay;
export function ErrorDisplay({
  title,
  message,
  button
}) {
  return <div className="p-4">
      <Header1 className="mb-4 border-b border-charcoal-800 pb-4">{title}</Header1>
      {message && <Header3>{message}</Header3>}
      <LinkButton to={button ? button.to : "/"} variant="primary/medium" className="mt-8">
        {button ? button.title : "Home"}
      </LinkButton>
    </div>;
}
_c2 = ErrorDisplay;
var _c, _c2;
$RefreshReg$(_c, "RouteErrorDisplay");
$RefreshReg$(_c2, "ErrorDisplay");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;